<template lang="pug">
.landing-page(v-if="screens.length > 0" ref="landingPage")
  div.section(v-for="(section, index) in screens" :key="index" :id="'section-' + index")
    WelcomeCommunitySlide(
      v-if="section.type === 'community_screen'"
      :title="section.title" 
      :description="section.description" 
      :items="section.community_or_experts"
      :index="index + 1"
    )
    WelcomeLeadersSlide(
      v-else-if="section.type === 'experts_screen'"
      :title="section.title" 
      :description="section.description" 
      :items="section.community_or_experts"
      :index="index + 1"
    )
    WelcomePartnersSlide(
      v-else-if="section.type === 'partners_screen'"
      :description="section.description"
      :image="section.image"
      :index="index + 1"
      :logos="section.logos"
    )
    WelcomeRegistrationSlide(
      v-else-if="section.type === 'authorization_screen'"
      :text="section.title"
      :index="index + 1"
    )
    WelcomeMainSlide(
      v-else
      :title="section.title" 
      :content="section.description"
      :buttonLink="section.link"
      :buttonText="section.button_text"
      :list="section.list"
      :image="section.image"
      :index="index + 1"
    )
  dots-navigation(:sections="screens" :currentSection="currentSection" @scrollToSection="scrollToSection")
</template>

<script setup lang="ts">
import DotsNavigation from '@/components/ui/DotsNavigation.vue'
import AppButton from '~/components/ui/Button/index.vue'
import Arrow from '@/assets/svg/anchor-arrow.svg'
import { useMainStore } from '@/stores/index'
import { useWelcomeStore } from '@/stores/welcome'

const mainStore = useMainStore()
const scrollPosition = ref(0)
const sectionElements = ref([])
const landingPage = ref()
const htmlContent = ""

const welcomeStore = useWelcomeStore()
const { screens } = storeToRefs(welcomeStore)

const { data: welcome } = useAsyncData(
  'welcome',
  async () => {
    await welcomeStore.fetchWelcome()
  }
)

const currentSection = computed(() => {
  for (let i = 0; i < screens?.value?.length; i++) {
    const element = sectionElements.value[i]
    if (element?.offsetTop <= scrollPosition.value && element?.offsetTop + element?.offsetHeight > scrollPosition.value) {
      return i
    }
  }
  return 0
})

const scrollToSection = (index: number) => {
  const element = sectionElements.value[index]
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
    scrollPosition.value = landingPage.value.scrollTop + window.innerHeight / 2
  }
}

const onScroll = () => {
  scrollPosition.value = landingPage.value.scrollTop + window.innerHeight / 2
}

const updateSectionElements = () => {
  sectionElements.value = screens.value.map((_, index) => document.getElementById(`section-${index}`))
}

onMounted(() => {
  mainStore.setFirstVisit()
  landingPage.value.addEventListener('scroll', onScroll)
  updateSectionElements()
})

onBeforeUnmount(() => {
  landingPage.value.removeEventListener('scroll', onScroll)
})

watch(currentSection, (newVal) => {
  const section = screens.value[newVal]
  const { isAuthFormOpen } = storeToRefs(mainStore)
  
  if (section && section.type === 'authorization_screen') {
    mainStore.setAuthForm(true)
    mainStore.hideOverlay()
    mainStore.setRegScreen(true)
  } else {
    if (isAuthFormOpen.value) {
      mainStore.setAuthForm(false)
    }
    mainStore.activateOverlay()
    mainStore.setRegScreen(false)
  }
})
</script>

<style lang="scss" scoped>
.landing-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.section {
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  &:nth-child(even) {
    &:deep(.container-section.is-main) {
      background: linear-gradient(to right, rgba(255, 248, 252, 1), rgba(255, 237, 235, 1), rgba(255, 255, 255, 1), rgba(255, 246, 245, 1), rgba(255, 233, 245, 1));
    }
  }
}

.dots-navigation {
  position: fixed;
  top: 50%;
  right: get-vw(94);
  transform: translateY(-50%);
}

a {
  text-decoration: none;
}

@include mobile {
  #section-5 {
    display: none;
  }
}

</style>
